<!--
    나눔스퀘어
-->
<template>
    <div class="nanum-square" v-html="this.html"></div>
</template>

<script>
export default {
    props: {
        html: String,
    },
}
</script>

<style scoped>
.nanum-square {
    font-family: NanumSquareL;
    width: auto;
}
</style>