<!--
    카테고리 배경화면 마스크
-->
<template>
    <div class="background-mask-vue" :class="[{draw: this.draw}, {on: this.on}]" @click="onClick()"></div>
</template>

<script>
export default {
    components: {
    },
    computed: {
        categoryOn: function () {
            return this.$store.state.categoryOn
        }
    },
    data () {
        return {
            draw: false,
            on: false,
            timeout: null,
        }
    },
    methods: {
        onClick: function () {
            this.$store.commit('categoryOn', false)
        }
    },
    mounted () {
    },
    watch: {
        categoryOn: function (value) {

            if (this.timeout != null) {
                clearTimeout(this.timeout)
                this.timeout = null
            }

            let self = this

            if (value) {
                this.draw = true
                this.timeout = setTimeout (function () {
                    self.on = true
                }, 50)
            } else {
                this.on = false
                this.timeout = setTimeout (function () {
                    self.draw = false
                }, 400)
            }
        }
    }
}
</script>

<style scoped>
.background-mask-vue {
    background-color: #000;
    display: none;
    height: 100vh;
    left: 0px; /* 카테고리가 밀고 나오는 공간만큼 띄움 */
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: all 0.4s ease;
    width: 100vw;
    z-index: 100;

    &.on {
        opacity: 0.7;
    }
}
.background-mask-vue.draw {
    display: block;
}
</style>