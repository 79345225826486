<template>
        <div class="left-category" :class="{on: this.categoryOn}">
            <div class="show-area">
                <div class="header">
                    <template v-if="this.login">
                        <NanumSquareBold class="user-nm" :html="this.userNm"></NanumSquareBold>
                        <img class="close" src="@/assets/images/삭제_흰색.png" @click="onClickClose()" />
                    </template>
                    <template v-else>
                        <NanumSquareBold class="user-nm" :html="this.text.login" @click="onClickLogin()" />
                        <div class="join" @click="onClickJoin()">
                            <img class="icon" src="@/assets/images/회원가입.png" />
                            <NanumSquareBold class="text" :html="this.text.join" />
                        </div>
                    </template>
                </div>
                <div class="title">
                    <NanumSquareBold class="caption" :html="this.text.title"></NanumSquareBold>
                    <div class="under-line"></div>
                </div>
                <div class="list" @scroll.stop="onScrollLeftCategoryList">
                    <div class="item" v-for="(item, index) in this.categories" :key="index" @click="onClickCategory(item)">
                        <img :src="item.icon" />
                        <NanumSquare class="caption" :html="item.label"></NanumSquare>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import NanumSquare from '@/components/common/NanumSquare.vue'
import NanumSquareBold from '@/components/common/NanumSquareBold.vue'

export default {
    components: {
        NanumSquare,
        NanumSquareBold,
    },
    computed: {
        categories: function () {
            return this.$store.state.categories
        },
        categoryOn() {
            return this.$store.state.categoryOn
        },
        login: function () {
            let memberInfo = JSON.stringify(this.$store.state.memberInfo)
            return memberInfo == '{}' ? false : true
        },
        userNm: function () {
            let memberInfo = JSON.stringify(this.$store.state.memberInfo)
            return memberInfo == '{}' ? '' : this.$store.state.memberInfo.memberName
        },
    },
    data () {
        return {
            accessToken: '',
            text: {
                join: '회원가입',
                login: '로그인하기',
                title: '카테고리',
            },
        }
    },
    methods: {
        apiGetCategories: function () {
            // 카테고리 목록
            let apiName = '카테고리 목록 불러오는 api'
            let self = this
            axios.get('https://shop-api.e-ncp.com/categories', {
                params: {
                    keyword: '',
                },
                headers: {
                    clientid: this.$store.state.clientid,
                    platform: this.$store.state.deviceType,
                    version: '1.0'
                }
            })
            .then((response) => {

                if (this.$store.state.debug) {
                    console.log(apiName, '응답', response)
                }

                self.setCategories(response)
            })
            .catch((e) => {
                alert('카테고리 목록을 불러오던 중 오류가 발생했습니다.')
            })
        },
        onClickCategory: function (item) {
            this.$router.push({name: '카테고리상품목록', params: {categoryNo: item.categoryNo}})
        },
        onClickClose: function () {
            this.$store.commit('categoryOn', false)
        },
        onClickJoin: function () {
            this.$store.commit('categoryOn', false)
            this.$router.push('join')
        },
        onClickLogin: function () {
            this.$store.commit('categoryOn', false)
            this.$router.push('login')
        },
        onScrollLeftCategoryList: function () {
        },
        setCategories: function (response) {

            if (response.status == 200) {
                localStorage.setItem('categories', JSON.stringify(response.data.multiLevelCategories))
                this.$store.commit('setCategories', response.data.multiLevelCategories)
            }
        }
    },
    mounted () {
        this.apiGetCategories()
    },
}
</script>

<style scoped>
.left-category {
    background-color: #fff;
    height: 100vh;
    left: 0px;
    position: absolute;
    top: 0px;
    transition: all 0.6s ease;
    width: 0px;
    z-index: 300;
    overflow: hidden;

    &.on {
        width: 157.5px;
    }
}
.left-category .show-area {
    /* background-color: #fff;
    height: 100vh;
    left: -157.5px;
    position: absolute;
    top: 0px;
    transition: all 0.6s ease;
    width: 157.5px;

    &.on {
        left: 0px;
    } */
}
.left-category .header {
    background-color: #f99300;
    height: 67.5px;
    position: relative;
    width: 157.5px;
}
.header .join {
    cursor: pointer;
    height: 67.53px;
    left: 114px;
    position: absolute;
    text-align: center;
    top: 0px;
    width: 44px;
}
.join .icon {
    height: 30px;
    left: 0px;
    margin-top: 10px;
    position: relative;
    top: 0px;
    width: 30px;
}
.join .text {
    color: #fff;
    font-size: 9px;
    height: 10px;
    line-height: 10px;
}
.header .user-nm {
    color: #fff;
    font-size: 17.5px;
    height: 17.5px;
    left: 25px;
    line-height: 17.5px;
    position: absolute;
    top: 26px;
    width: auto;
}
.header .close {
    cursor: pointer;
    height: 18.2475px;
    left: 114.2525px;
    position: absolute;
    top: 27.0025px;
    width: 18.2475px;
}

.left-category .title {
    background-color: #fff;
    border-bottom: 1px #dcdcdc solid;
    height: 40px;
    position: relative;
    width: 157.5px;
}
.title .caption {
    color: #303030;
    font-size: 12.5px;
    height: 12.5px;
    left: 25px;
    line-height: 12.5px;
    position: absolute;
    top: 15px;
    width: auto;
}
.title .under-line {
    background-color: #f99300;
    height: 2.5px;
    left: 25px;
    position: absolute;
    top: 39px;
    width: 44.5px;
}

.left-category .list {
    background-color: #fff;
    height: calc(100vh - 107.5px);
    overflow-y: auto;
    position: relative;
    width: 157.5px;
}
.list .item {
    background-color: #fff;
    cursor: pointer;
    height: 47.5px;
    position: relative;
    width: 157.5px;
}
.list .item img {
    position: absolute;
    left: 26.25px;
    width: 28.8875px;
    height: 22.95px;
    top: 12.275px;
}
.list .item .caption {
    color: #b4b4b4;
    font-size: 11.5px;
    height: 11.5px;
    left: 68px;
    line-height: 11.5px;
    position: absolute;
    top: 18px;
    width: auto;
}
</style>