import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {component: () => import(/* webpackChunkName: "beStore" */ '../views/BeStore.vue'), name: '입점제안', path: '/be_store',},
  {component: () => import(/* webpackChunkName: "beStore" */ '../views/BeStoreView.vue'), name: '입점제안보기', path: '/be_store_view',},
  {component: () => import(/* webpackChunkName: "beStore" */ '../views/BeStoreWrite.vue'), name: '입점제안등록', path: '/be_store_write',},
  {component: () => import(/* webpackChunkName: "beStoreList" */ '../views/BeStoreList.vue'), name: '입점제안목록', path: '/be_store_list',},
  {component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue'), name: '장바구니', path: '/cart',},
  {component: () => import(/* webpackChunkName: "categoryProducts" */ '../views/CategoryProducts.vue'), name: '카테고리상품목록', path: '/category_products/:categoryNo',},
  {component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon.vue'), name: '쿠폰', path: '/coupon',},
  {component: () => import(/* webpackChunkName: "customerSupport" */ '../views/CustomerSupport.vue'), name: '고객지원', path: '/customer_support',},
  {component: () => import(/* webpackChunkName: "findId" */ '../views/FindId.vue'), name: '아이디 찾기', path: '/find_id',},
  {component: () => import(/* webpackChunkName: "findPasswrord" */ '../views/FindPassword.vue'), name: '비밀번호 찾기', path: '/find_password',},
  {component: () => import(/* webpackChunkName: "home2" */ '../views/Home2.vue'), name: '홈2', path: '/',},
  {component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'), name: '홈(메인)', path: '/home',},
  {component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue'), name: '회원가입', path: '/join',},
  {component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'), name: '로그인', path: '/login',},
  {component: () => import(/* webpackChunkName: "myPage" */ '../views/MyPage.vue'), name: '마이페이지', path: '/my_page',},
  {component: () => import(/* webpackChunkName: "myReview" */ '../views/MyReview.vue'), name: '내리뷰', path: '/my_review',},
  {component: () => import(/* webpackChunkName: "orderDetail" */ '../views/OrderDetail.vue'), name: '주문상세', path: '/order_detail',},
  {component: () => import(/* webpackChunkName: "orderSheet" */ '../views/OrderSheet.vue'), name: '주문서작성', path: '/order_sheet/:orderSheetNo',},
  {component: () => import(/* webpackChunkName: "orderStatus" */ '../views/OrderStatus.vue'), name: '주문 및 배송', path: '/order_status',},
  {component: () => import(/* webpackChunkName: "passwordReset" */ '../views/PasswordReset.vue'), name: '비밀번호 재설정', path: '/password_reset',},
  {component: () => import(/* webpackChunkName: "pickedItems" */ '../views/PickedItems.vue'), name: '찜한상품', path: '/picked_items',},
  {component: () => import(/* webpackChunkName: "pickedStores" */ '../views/PickedStores.vue'), name: '찜한스토어', path: '/picked_stores',},
  {component: () => import(/* webpackChunkName: "point" */ '../views/Point.vue'), name: '포인트', path: '/point',},
  {component: () => import(/* webpackChunkName: "productView" */ '../views/ProductView.vue'), name: '상품상세보기', path: '/product_view/:productNo',},
  {component: () => import(/* webpackChunkName: "recentlyViewedItems" */ '../views/RecentlyViewedItems.vue'), name: '최근본상품', path: '/resently_viewed_items',},
  {component: () => import(/* webpackChunkName: "registRevie" */ '../views/RegistReview.vue'), name: '리뷰작성하기', path: '/regist_review',},
  {component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'), name: '검색', path: '/search/:searchParam',},
  {component: () => import(/* webpackChunkName: "search" */ '../views/Search2.vue'), name: '검색2', path: '/search',},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
