<template>
    <div class="app-wrapper">
        <div class="product-view-page app-contents" :class="{category: categoryOn}">
            <LeftCategory />
            <router-view/>
            <BottomMenu v-if="bottomMenuOn" />
            <BackgroundMask />
        </div>
    </div>
</template>

<script>
import BackgroundMask from '@/components/BackgroundMask.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import LeftCategory from '@/components/LeftCategory.vue'

export default {
    components: {
        BackgroundMask,
        BottomMenu,
        LeftCategory,
    },
    computed: {
        categoryOn: function () {
            return this.$store.state.categoryOn
        },
        bottomMenuOn: function () {
            return this.$store.state.bottomMenuOn
        },
    },
    data() {
        return {
            category: false,
        }
    },
    methods: {
        getAgent: function () {
            let userAgent = navigator.userAgent.toLowerCase()
            let isAndroid = userAgent.indexOf('android') > -1
            let isIphone = userAgent.indexOf('iphone') > -1
            let deviceType = ''
            let deviceName = ''

            if (isAndroid) {
                deviceType = 'MOBILE_WEB'
                deviceName = 'android'
            } else if (isIphone) {
                deviceType = 'MOBILE_WEB'
                deviceName = 'iphone'
            } else {
                deviceType = 'PC'
                deviceName = 'PC'
            }

            this.$store.commit('setDeviceType', deviceType)
            this.$store.commit('setDeviceName', deviceName)
        },        
        getSavedCategories: function () {
            
            try {
                let temp = localStorage.getItem('categories') == null ? [] : localStorage.getItem('categories')
                temp = JSON.parse(temp)
                this.$store.commit('setCategories', temp)
            } catch (error) {
                console.log(error)
            }
        },
    },
    mounted() {
        this.getSavedCategories()
        this.getAgent()
    },
    watch: {
        categoryOn: function (value) {

        }
    }
}    
</script>

<style>
#app {
    font-family: Nanum Gothic;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.app-wrapper {
    height: 100vh;
    left: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 0px;
    width: 100vw;
}
/* .app-wrapper .app-contents {
    height: auto;
    left: -157.5px;
    position: fixed;
    top: 0px;
    width: calc(100vw + 157.5px);
    transition: all 0.4s ease;

    &.category {
        left: 0px;
    }
} */
.app-wrapper .app-contents {
    height: auto;
    position: fixed;
    top: 0px;
    width: 100vw;
    transition: all 0.4s ease;
}
.masking-left {
    background-color: #fff;
    height: 100vh;
    left: 0px;
    position: absolute;
    top: 0px;
    width: calc(50vw - 320px);
    z-index: 101;
}
.masking-right {
    background-color: #fff;
    height: 100vh;
    left: calc(50vw + 320px);
    position: absolute;
    top: 0px;
    width: calc(50vw - 320px);
    z-index: 101;
}
@font-face {
    font-family:'NanumGothic';
    src: url('assets/fonts/NanumGothic.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumGothicBold';
    src: url('assets/fonts/NanumGothicBold.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumGothicExtraBold';
    src: url('assets/fonts/NanumGothicExtraBold.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumGothicLight';
    src: url('assets/fonts/NanumGothicLight.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquare_acB';
    src: url('assets/fonts/NanumSquare_acB.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquare_acEB';
    src: url('assets/fonts/NanumSquare_acEB.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquare_acL';
    src: url('assets/fonts/NanumSquare_acL.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquare_acR';
    src: url('assets/fonts/NanumSquare_acR.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquareB';
    src: url('assets/fonts/Pretendard-Bold.woff') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquareEB';
    src: url('assets/fonts/Pretendard-ExtraBold.woff') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquareL';
    src: url('assets/fonts/Pretendard-Light.woff') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumSquareR';
    src: url('assets/fonts/Pretendard-Regular.woff') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumBarunGothic';
    src: url('assets/fonts/NanumBarunGothic.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumBarunGothicBold';
    src: url('assets/fonts/NanumBarunGothicBold.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumBarunGothicLight';
    src: url('assets/fonts/NanumBarunGothicLight.ttf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family:'NanumBarunGothicUltraLight';
    src: url('assets/fonts/NanumBarunGothicUltraLight.ttf') format('opentype');
    font-weight: 900;
}
</style>