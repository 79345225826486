<!--
    나눔스퀘어볼드
-->
<template>
    <div class="nanum-square-bold" v-html="this.html"></div>
</template>

<script>
export default {
    props: {
        html: String,
    },
}
</script>

<style scoped>
.nanum-square-bold {
    font-family: NanumSquareB;
    width: auto;
}
</style>