import { createStore } from 'vuex'
import axios from 'axios'
import router from '@/router'

export default createStore({
    state: {
        beStoreItem: {},
        bottomMenuOn: false,
        cart: [],
        categories: [],
        category: {
            categoryNo: '',
            item: {},
        },
        categoryOn: false,
        clientid: 'drphB5vRh1z68onU4eFZJQ==',
        debug: false,
        deviceType: 'MOBILE_WEB',
        deviceName: '',
        likeProduct: {
            productNo: '',
            liked: false,
        },
        main: {
            discount2: {
                index: 0,
            }
        },
        memberInfo: {},
        museLoginScreens: [
            'cart',
            'coupon',
            'my_page',
            'my_review',
            'order_status',
            'order_detail',
            'picked_items',
            'picked_stores',
            'point',
            'regist_review',
        ],
        mustLogoutScreens: [
            'find_id',
            'find_password',
            'join',
            'login'
        ],
        orderItem: {},
        rankItems: [],
        reviewItem: {},
        selectedProductNo: -1,
    },
    getters: {
    },
    mutations: {
        bottomMenuOn: function (state, payload) {
            // 하단 메뉴 On/Off
            state.bottomMenuOn = payload
        },
        categoryOn: function (state, payload) {
            // 카테고리 On/Off
            state.categoryOn = payload
        },
        checkLogin: function (state, payload) {

        },
        checkLogout: function (state, payload) {

        },
        discount2Index: function (state, payload) {
            // 메인의 할인상품2의 탭 index 설정
            state.main.discount2.index = payload
        },
        getCart: function (state) {
            // 장바구니 목록을 불려온다.
            let apiName = '장바구니 조회 api'
            let self = state

            let accessToken = localStorage.getItem('accessToken') == null ? '' : localStorage.getItem('accessToken')

            if (accessToken == '') {
                self.cart = []
                return
            }

            axios.get('https://shop-api.e-ncp.com/cart', {
                params: {
                },
                headers: {
                    accessToken: accessToken,
                    clientid: state.clientid,
                    divideInvalidProducts: true,
                    language: 'ko',
                    platform: state.deviceType,
                    version: '1.0'
                }
            })
            .then((response) => {

                if (state.debug) {
                    console.log(apiName, '응답', response)
                }

                if (response.status == 200) {
                    self.cart = response.data
                }
            })
            .catch((e) => {
                this.cart = []
                console.log(apiName, '오류', e)
                /**
                 * 오류무시
                 */
            })
        },
        getMemberInfo: function (state, payload) {
            /**
             * 회원정보를 불러온다.
             * @param payload.message 회원정보를 불러오는데 실패할 경우 보여줄 메시지
             * @param payload.url 회원정보를 정상적으로 불러왔을 때 이동할 url
             */
            let apiName = '회원정보 조회 api'
            let self = state
            let accessToken = localStorage.getItem('accessToken') == null ? '' : localStorage.getItem('accessToken')

            if (accessToken == '') {

                if (payload.message != '') {
                    // alert(payload.message)
                    return
                }

                return
            }

            axios.get('https://shop-api.e-ncp.com/profile', {
                params: {
                },
                headers: {
                    accessToken: accessToken,
                    clientid: state.clientid,
                    platform: state.deviceType,
                    version: '1.0'
                }
            })
            .then((response) => {

                if (state.debug) {
                    console.log(apiName, '응답', response)
                }

                if (response.status == 200) {
                    self.memberInfo = response.data

                    if (payload.url != '') {
                        router.push(payload.url)
                    }
                }
            })
            .catch((e) => {
                self.memberInfo = {}
                console.log(apiName, '오류', e)
                /**
                 * 오류무시
                 */
                if (e.response.status == 401) {
                    localStorage.removeItem('accessToken')
                }
            })
        },
        setBeStore: function (state, payload) {
            // 입점문의 세팅
            state.beStoreItem = payload
        },
        setCategory: function (state, payload) {
            // 카테고리 선택 시 저장
            state.category = payload
        },
        setCategories: function (state, payload) {
            // 서버에서 불러온 카테고리 설정
            state.categories = payload
        },
        setDeviceName: function (state, payload) {
            // 장비명
            state.deviceName = payload
        },
        setDeviceType: function (state, payload) {
            // 장비의 Device type 설정
            state.deviceType = payload
        },
        setLikeProduct: function (state, payload) {
            // 좋아요 한 상품... 메인에서 다른 목록에 있는 동일상품의 좋아요 상태를 갱신하기 위해 사용
            state.likeProduct.productNo = payload.productNo
            state.likeProduct.liked = payload.liked
        },
        setMemberInfo: function (state, payload) {
            // 회원정보 세팅
            state.memberInfo = payload
        },
        setOrderItem: function (state, payload) {
            // 주문 아이템 세팅(주문상세용)
            state.orderItem = payload
        },
        setRankItems: function (state, payload) {
            // 이전 검색어 저장
            state.rankItems = payload
        },
        setReviewItem: function (state, payload) {
            // 리뷰 아이템 세팅
            state.reviewItem = payload
        },
        setSelectedProductNo: function (state, payload) {
            // 선택된 상품번호 세팅
            state.selectedProductNo = payload
        }
    },
    actions: {
    },
    modules: {
    }
})
