<!--
    하단메뉴
-->
<template>
    <div class="bottom_menu">
        <div v-for="(item, index) in this.menus" :key="index">
            <div class="item" @click="onClickMenu(index)">
                <img :src="item.icon" />
                <div class="caption">{{ item.caption }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    computed: {
        isIphone: function () {
            return this.$store.state.deviceName == 'iphone'
        }
    },
    data () {
        return {
            menus: [
                {
                    caption: '카테고리',
                    icon: require('@/assets/images/하단메뉴_카테고리.png'),
                    url: '',
                },
                {
                    caption: '장바구니',
                    icon: require('@/assets/images/하단메뉴_장바구니.png'),
                    url: '/cart',
                },
                {
                    caption: '홈',
                    icon: require('@/assets/images/하단메뉴_홈.png'),
                    url: '/home',
                },
                {
                    caption: '최근본',
                    icon: require('@/assets/images/하단메뉴_최근본.png'),
                    url: '/resently_viewed_items',
                },
                {
                    caption: '마이페이지',
                    icon: require('@/assets/images/하단메뉴_마이페이지.png'),
                    url: '/my_page',
                },
            ],
        }
    },
    methods: {
        onClickMenu: function (index) {
            /**
             * 메뉴 클릭 처리
             * 카테고리는 왼쪽에서 카테고리 리스트가 나오고 나머지는 routing
             */
            switch (index) {
                case 0:
                    this.$store.commit('categoryOn', true)
                    break
                default:
                    this.$router.push(this.menus[index].url)
                    break
            }
        }
    },
    mounted () {
    },
}
</script>

<style scoped>
.bottom_menu {
    background: linear-gradient(90deg, #f99300, #ff5615);
    height: 49.25px;
    left: 0px; /* 카테고리가 밀고 나올 공간만큼 띄움 */
    position: fixed;
    top: calc(100vh - 49.25px);
    width: 100vw;
    z-index: 50;
}
.bottom_menu.iphone {
    top: calc(100vh - 130px);
}
.bottom_menu.pc {
    width: 640px;
}
.bottom_menu .item {
    color: #fff;
    float: left;
    font-size: 9px;
    height: 49.25px;
    position: relative;
    text-align: center;
    width: 20%;
}
.item .caption {
    position: absolute;
    top: 31.73px;
    width: 100%;
}
.item img {
    width: 21.5px;
    height: 19.5px;
    margin-top: 7.75px;
}
</style>